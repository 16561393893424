import React, {Component} from 'react';
import './ScreeningCriteria.css';
import {BsFillCaretDownFill} from "react-icons/bs";
import Select from 'react-select'

class ScreeningCriteria extends Component {
    
    constructor(props) {
        super(props);

        this.customStyles = {
            control: (provided) => ({
                ...provided,
                boxShadow: "none",
                borderColor: "#e3e8e6",
                '&:hover': { borderColor: '#e3e8e6' }, // border style on hover
            }),
            option: (provided, state) => ({
                ...provided,
                // '&:hover': { borderColor: '#bbb' }, // border style on hover
                // margin: "5px 0px",
                // paddingLeft: "10px"
                textAlign: "left",
                '&:hover': { backgroundColor: state.isSelected ? '#abbab4' : '#e3e8e6'}, // border style on hover
                //'&:hover': { backgroundColor: '#bbb' }, // border style on hover
                backgroundColor: state.isSelected ? '#abbab4' : 'white',
            }),
            singleValue: (provided) => ({
                ...provided,
                color: "#677e75", 
                //fontWeight: "bold" ,
                textAlign: "left",
            }),
        }

        this.substaintitalContributionCriteria = "Substaintial Contribution Criteria"
        this.DNSHcriteria = "Does-No-Significant-Harm Criteria"

        this.objectives = this.props.objectives
        
        //make objectiveOptions for dropdown 
        this.objectiveOptions = this.props.activity.screeningCriteria.map(d => {
            return {
                label: this.objectives.find(d1 => d1.key === d.objective).name,
                value: d.objective 
            }
        })
        //set default selected objective (for option list, selected criteria and heading for criteria description) (either first objective/substantial_contribution_criteria of if selecetd via "objective path")
        let objectiveSelected;
        let criteraSelected;
        this.criteriaDescription = ""
        if (this.props.objectiveSelected) { //case where objective is defined (objective path)
            objectiveSelected = this.objectiveOptions.find(d => d.value === this.props.objectiveSelected)
            criteraSelected = objectiveSelected.value 
            this.criteriaDescription = this.props.activity.screeningCriteria.find(d => d.objective === objectiveSelected.value).substantial_contribution_criteria
        } else { ////case where secgtor or search paths are used
            objectiveSelected = this.objectiveOptions.find(d => d.value === this.props.activity.screeningCriteria[0].objective)
            criteraSelected = objectiveSelected.value 
            this.criteriaDescription = this.props.activity.screeningCriteria[0].substantial_contribution_criteria
        }
        this.screeningCriteria = this.props.activity.screeningCriteria.find(d => d.objective === objectiveSelected.value)
        this.descriptionHeader = `${this.substaintitalContributionCriteria} (${objectiveSelected.label})`
        //this.descriptionHeader = `${this.substaintitalContributionCriteria} criteria (${this.objectives.find(d => d.key === this.screeningCriteria.objective)?.name})`

        this.state = {
            objectiveSelected: objectiveSelected,
            criteraSelected: criteraSelected
        }
        
        
    }

    onSelectorObjectiveChange(option) {
        
        this.screeningCriteria = this.props.activity.screeningCriteria.find(d => d.objective === option.value)
        this.descriptionHeader = `${this.substaintitalContributionCriteria} (${this.objectives.find(d => d.key === option.value)?.name})`
        this.criteriaDescription = this.screeningCriteria.substantial_contribution_criteria
        this.setState(
            {
                objectiveSelected: option,
                criteraSelected: this.screeningCriteria.objective,
            }
        )
    }

    onScreeningCriteriaClick(objectiveKey) {
        if (objectiveKey === this.screeningCriteria.objective) {
            this.criteriaDescription = this.screeningCriteria.substantial_contribution_criteria?.[0] || null
            this.descriptionHeader = `${this.substaintitalContributionCriteria} (${this.objectives.find(d => d.key === objectiveKey)?.name})`
        } else {
            this.criteriaDescription = this.screeningCriteria.dnsh.find(d => d.objective === objectiveKey)?.criteria?.[0] || null
            this.descriptionHeader = `${this.DNSHcriteria} (${this.objectives.find(d => d.key === objectiveKey)?.name})`
        }
        
        this.setState({
            criteraSelected: objectiveKey
        })
    }

    onExpansionClick() {
        this.setState({isDesciptionExpanded: !this.state.isDesciptionExpanded})
    }
    
    render() {
        return (
            <div className = "screeningCriteria">
                <div className="objectivesContainer">
                    <div className="intro">
                        The economic activity is <i>sustainable ("Taxonomy-aligned")</i> if it complies with following criteria: 
                    </div>
                    <div className="activityTitle">
                        {this.props.activity.name}
                    </div>
                    <div className="objectivesContainerHeaderLine">
                        <div className="objectiveList">
                            <div className="caption">Select qualifying objective:</div>
                            <div className="select">
                                <Select
                                    onChange={(option) => this.onSelectorObjectiveChange(option)}
                                    // onBlur= { event => event.preventDefault()}
                                    styles={this.customStyles}
                                    options={this.objectiveOptions}
                                    //components={{ Option: CustomSelectOption, SingleValue: CustomSelectValue}}
                                    value= {this.state.objectiveSelected}
                                    isSearchable={false}
                                />
                            </div>
                        </div>
                        {this.screeningCriteria.activity_contribution_type && 
                            <div className='activityType'>{this.screeningCriteria.activity_contribution_type.toLowerCase() === "transitional" ? "T" : "E" }</div>
                        }
                    </div>
                    <div
                        className='closeButton button'
                        onClick = {(event) => this.props.onActivityClick(event, null)}
                    >
                        Close
                    </div>
                    <div className="objectives">
                        {this.objectives.map((objective) =>
                            <div 
                                key = {objective.key}
                                className={`objective ${objective.key === this.screeningCriteria.objective ? "substaintialContribution" : ""} ${objective.key === this.state.criteraSelected ? "selectedCriteria" : ""}`}
                                onClick = {() => this.onScreeningCriteriaClick(objective.key)}
                            >
                                <div className="objectiveHeader">
                                    <div className="icon">
                                        <objective.Icon/>
                                    </div>
                                    <div className="name">
                                        {objective.long_name}
                                    </div>
                                </div>
                                <div className='criteriaType'>
                                    {objective.key === this.screeningCriteria.objective ? this.substaintitalContributionCriteria : this.DNSHcriteria}
                                </div>
                                {objective.key === this.state.criteraSelected &&
                                    <div className="arrowIcon"><BsFillCaretDownFill/></div>
                                }
                            </div>
                        )}
                    </div>
                </div>
                <div className='criteriaDescription'>
                    <div className='heading'>{this.descriptionHeader}</div>
                    <div className='text' dangerouslySetInnerHTML={{ __html: this.criteriaDescription }}></div>
                </div>

            </div>
        );
    }
}

export default ScreeningCriteria;
  

