import React, {Component} from 'react';
import './ActivityItem.css';
import {AiFillCaretRight, AiFillCaretDown} from "react-icons/ai";
import ScreeningCriteria from '../ScreeningCriteria/ScreeningCriteria'

class ActivityItem extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            //activitySelected: this.sectors[0].name,
            isDesciptionExpanded: this.props.isDesciptionExpanded,
        }
    }

    onExpansionClick() {
        this.setState({isDesciptionExpanded: !this.state.isDesciptionExpanded})
    }

    render() {
        return (
            <div className = "activityItem">
                <div 
                    className={`activity ${this.props.isSelected ? "selectedActivity" : ""}`}
                   // onClick = {(event) => this.props.onActivityClick(event, this.props.activity.name)}
                    onClick = {() => this.onExpansionClick()}
                >
                    <div className='activityNaceCode'>
                        {this.props.activity.nace_codes}
                    </div>
                    <div 
                        className="activityHeader"
                    >
                        <div className='activityName'>
                            {this.props.activity.name}
                        </div>
                        <div 
                            className='expansionArrow'
                        >
                            {this.state.isDesciptionExpanded ? <AiFillCaretDown/> : <AiFillCaretRight/>}
                        </div>
                        <div
                            className='seeCriteriaButton button'
                            onClick = {(event) => this.props.onActivityClick(event, this.props.activity.name)}
                        >
                            Criteria
                        </div>
                        {/* {this.props.activity.screeningCriteria.activity_contribution_type && 
                            <div className='activityType'>{this.props.activity.screeningCriteria.activity_contribution_type.toLowerCase() === "transition" ? "T" : "E" }</div>
                        } */}
                    </div>
                    {this.state.isDesciptionExpanded &&
                        <div 
                            className='activityDescription' 
                            dangerouslySetInnerHTML={{ __html: this.props.activity.description }}>
                        </div>
                    }
                </div>
                {this.props.isSelected &&
                    <div className='screeningCriteriaContainer'>
                        <ScreeningCriteria
                            activity = {this.props.activity}
                            objectives = {this.props.objectives}
                            objectiveSelected = {this.props.objectiveSelected}
                            onActivityClick = {(event) => this.props.onActivityClick(event, null)}
                        />
                    </div>
                }
            </div>
        );
    }
}


export default ActivityItem;
  

