import React, {Component} from 'react'
import './AboutUs.css';

class AboutUs extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            taxonomyQualifierData: {}
        }
    }

    render() {
        //console.log("taxonomy tsrater")
        return (
            <div className="contact">  
               <div className="mainsection section centered aboutus">
                    <div className="heading main centered">
                        The team behind <span className="hl-yellow">Sustain Agents</span>
                    </div>
                    <div className="content">
                        <div className="paragraph">
                            Sustain Agents is a Danish team focusing solely on data management and reporting within <i>sustainability</i>. 
                            <br/><br/>
                            Our ambition is to make collection and processing of sustainability data easy and effortless for our customers. 
                            <br/><br/>
                            With the global and European sustainability goals comitted to by the EU, the EU and the national lawmakers are expected to continuously introducing new legislation for capital markets and the business sector to complay with. New legislation includes for example the EU Taxonomy Regulation, Corporate Sustainability Reporting Directive (CSRD) and the Product Environmental Footprint guide.
                            <br/><br/>
                            In Sustain Agents, we continuously keep us updated on new legislation to come and, when possible, provide input to lawmakers in the law making process.      
                            <br/><br/>
                            The people behind Sustain Agents have strong competences and background within sustainability lawmaking, financial and operational reporting, business intelligence systems, software development and ERP implementations.      
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

export default AboutUs;
  

