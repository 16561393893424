import React, {Component} from 'react'
//import  from '../Guide/Guide';
//import './susacc.css';

class Susacc extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            taxonomyQualifierData: {}
        }
    }

    render() {
        //console.log("taxonomy tsrater")
        return (
            <div>
                <div className="mainsection">
                    <div className="section introduction">
                        <div className="heading main"><span className="hl-yellow">SUSACC</span> is a smart and flexible sustainability accounting and reporting software</div>
                        <div className="content">
                            <div className="paragraph">
                                Many companies have ambitions of extensive sustainability accounting and reporting. The collecting, storing and processing of the necessary sustainability data however often turns out to be a complex and comprehensive task not easy to implement.
                                <br/><br/>
                                The data collection challenges are numerous: Many data need be gathered locally, others require deliveries from business partners. Furthermore, the collected data is often unstructured in nature and form.
                                <br/><br/>
                                The challenges are likely to increase with the imminent introduction of the new EU regulations and directives for capital markets and companies, aiming to provide the framework for more comparable, reliable and transparent sustainability reporting. Such legislation includes the EU Taxonomy Regulation, the Corporate Sustainability Reporting Directive and the Product Environmental Footprint guide.
                                <br/><br/>
                                <span className="hl-yellow">SUSACC</span> solves most companies' reporting challenges, from collection of data to business analytics for strategy purposes and final presentation of statutory reports.
                                <br/><br/>    
                            </div>
                        </div>
                    </div>
                    <div className="section centered systemOverview">
                        <div className="heading main centered"><span className="hl-yellow">SUSACC</span> solves four major sustainability reporting challenges</div>
                        <div className="content">
                            <div className="imgContainer">
                                <img src="./img/product_overview.png"/>
                            </div>
                            <div className="paragraph">
                                <div className="reportingNeed">
                                    <span className="hl-yellow">Materiality identification:</span> Defining the measures and indicators to be included in the company’s sustainability reporting.
                                </div>
                                <div className="reportingNeed">
                                    <span className="hl-yellow">Collection of data:</span>  Collecting data across all the company's locations and data sources whether in structured or unstructured formats.
                                </div>
                                <div className="reportingNeed">
                                    <span className="hl-yellow">Processing of data:</span> Processing the collected data to a set of reporting measures.
                                </div>
                                <div className="reportingNeed">
                                    <span className="hl-yellow">Reporting and analytics:</span> Providing statutory report and business analytics for strategy making and implementation monitoring. 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section wide materiality">
                        <div className="heading">Materiality identification</div>
                        <div className="content">
                            <div className="imgContainer">
                                <img src="./img/materiality.png"/>
                            </div>  
                            <div className="paragraph">
                                <span className="hl-yellow">SUSACC</span> provides tools for identification of measures and indicators relevant to the company's sustainability reporting. The materiality identification is based on three approaches:
                                <br/>
                                <ol>
                                    <li>
                                        the company's own sustainability strategy
                                    </li>
                                    <br/>
                                    <li>
                                        methodologies given by major voluntary standards which the company might adhere to e.g. GRI or SASB
                                    </li>
                                    <br/>
                                    <li>
                                        mandatory requirements and standards given by the legislation (expected being introduced the coming years).
                                    </li>
                                </ol>
                            </div>
                        </div>    
                    </div>
                    <div className="section wide datacollection">
                        <div className="heading">Collection of data</div>
                        <div className="content">
                            <div className="paragraph">
                                <b>Collecting the data</b>
                                <br/><br/>
                                With <span className="hl-yellow">SUSACC</span> the company can efficiently and in a flexible way collect structured and unstructured data from the organisation.
                                <br/><br/>
                                The sustainability responsible can choose to either automatically retrieve data by integrating to the company's existing data systems via <span className="hl-yellow">SUSACC's</span> comprehensive API or manually collecting data by a highly flexible data templating tool.
                                <br/><br/>
                                The templating tool enables collection of unstructured and local stored data across organisational units and locations via a distributed templating logic.
                                <br/><br/>
                                With the distributed templating logic collection tasks can easily be allocated and further branched out (re-distributed) throughout the organisation. 
                                The tool will also easily handle any adjustments to the data collection needs should such arise ad-hoc during the collection process.   
                                <br/><br/>
                                The templating tool further allows data to be collected from external business partners (e.g. suppliers). 
                                <br/><br/>
                                All collected data are instantly stored in a central database.
                                <br/><br/>
                                Documentation (e.g. pdf, excel, screenshots) can be attached to each collection item by the individual reporting units.
                            </div>
                            <div className="imgContainer white">
                                <img src="./img/data_collection1.png"/>
                            </div>
                        </div>
                        <div className="content">
                            <div className="paragraph">
                                <b>Monitor the collection process</b>
                                <br/><br/>
                                The sustainability responsible can monitor the data collection process real-time getting statuses of all templates, whether branched out throughout the organisation or sent to business partners.   
                                <br/><br/>
                                This includes the completion degree by template or organisational unit as well as ongoing review of aggregated data collected and final measures to report. 
                            </div>
                            <div className="imgContainer">
                                <img src="./img/data_collection.png"/>
                            </div>  
                        </div>
                    </div>
                    
                    <div className="section wide reportingNeed dataprocessing">
                        <div className="heading">Processing of data</div>
                        <div className="content">
                            <div className="imgContainer">
                                <img src="./img/data_processing.png"/>
                            </div>
                            <div className="paragraph">
                                The collected data is processed in accordance with the selected reporting scope, allowing the company to instantly and continuously producing measures and indicators for relevant analysis (also during the data collection process).
                                <br/><br/>
                                All relevant conversion standards are embedded in the system, e.g. fuel to energy, N<sub>2</sub>O to CO<sub>2</sub> etc.
                            </div>
                        </div>
                    </div>    
                    <div className="section wide reportingNeed reporting">
                        <div className="heading">Reporting and analytics</div>
                        <div className="content">
                            <div className="paragraph">
                                <b>Statutory reporting</b>
                                <br/><br/>
                                <span className="hl-yellow">SUSACC</span> produces a statutory report based on the defined report scope (easy to print and distribute).
                                <br/><br/>
                                All measures and indicators can easily be exported and used in other systems and publishing tools (csv, xml, excel)
                                <br/><br/>
                                The reporting data is made ready to include <i>electronic tagging</i> (XRBL) as to be required by the authorities (EU).
                                <br/><br/>
                                The reporting process is prepared to support the auditing process having comprehensive data collection tracing and gathered documentation from the reporting organisation.
                            </div>
                            <div className="imgContainer">
                                <img src="./img/reporting1.png"/>
                            </div>
                        </div>
                        <div className="content">
                            <div className="imgContainer">
                                <img src="./img/reporting2.png"/>
                            </div>
                            <div className="paragraph">
                                <b>Analytics and internal reporting</b>
                                <br/><br/>
                                With the <span className="hl-yellow">SUSACC</span> software comes also a comprehensive and advanced analytics tool allowing the sustainability responsible to break down every measure within the given activities, regions, and reporting units (based on your data collection structure) helping the sustainability responsible to better understand how and where the company makes its sustainability impact.
                                <br/><br/>
                                The analytics module provides the company with the factual basis for formulating it's sustainability strategies and monitoring the implementation of initiatives according to set targets.
                            </div>
                            
                        </div>
                    </div>    
                </div>
                <div className="mainsection section centered">
                    <div className="heading main centered">
                        Cloud based solution
                    </div>
                    <div className="content">
                        <div className="paragraph">
                            <span className="hl-yellow">SUSACC</span> is a cloud based solution, which means that no technical installations are required either centrally or by the users. All collected data are securely stored on a hosted company specific database with easy access for the company. 
                            <br/><br/>
                            The tool is browser based and all users get access to the system via their favorite major browser on secure and encrypted connections. 
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

export default Susacc;
  

