import React, {Component} from 'react';
import './ActivityList.css';
import ActivityItem from '../ActivityItem/ActivityItem';

class ActivityList extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            selectedActivity: null
        }
    }

    onActivityClick(event, name) {
//        if (event.target.classList.contains("activityName") || event.target.classList.contains("expansionArrow")) return 
 //       if (event.target.classList.contains("activityName") || event.target.classList.contains("expansionArrow")) return 
        this.setState({
            selectedActivity: name
        })
    }

    render() {
        return (
            <div className="activityList">
                <div className="activityHeading">Economic activities</div>
                <div className="activityItems">
                    {this.props.activities.length === 0 && this.props.objectiveSelected && 
                        <div className="noActivitiesMeassage">
                            Economic activities for this objective are not yet defined by EU. <br></br><br></br> Awaits the "Environmetal Deligated Act" expected introduced spring 2022
                        </div>
                    } 
                    {this.props.activities.map((activity) => (
                        <ActivityItem
                            key = {activity.name}
                            activity = {activity} 
                            isSelected = {this.state.selectedActivity === activity.name}
                            objectives = {this.props.objectives}
                            objectiveSelected = {this.props.objectiveSelected}
                            onActivityClick = {(event, name) => this.onActivityClick(event, name)}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default ActivityList;
  

