import React, {Component} from 'react';
import './SearchPath.css';
import ActivityList from '../../ActivityList/ActivityList';
import {FiSearch} from "react-icons/fi";

class SearchPath extends Component {
    
    constructor(props) {
        super(props);
//        this.sectors = this.props.taxonomyQualifierData.sectors
        //this.objectives = this.props.taxonomyQualifierData.objectives.map(d => d.key = d.name) 
        // this.props.taxonomyQualifierData.activities.forEach(activity => {
        //     activity.screeningCriteria = this.props.taxonomyQualifierData.matches.find(d => activity.name === d.activity)
        // })
    
        this.state = {
            searchString: ""
        }
    }

    onSearchStringChange(event) {
        this.setState({
            searchString: event.target.value
        })
    }

    render() {
        return (
            <div className="searchGuideView">
                <div className="searchView">
                    <div className="searchHeader">Search activities:</div>
                    <div className="searchInputFieldContainer">
                        <div className= "icon" >
                            <FiSearch/>
                        </div>
                        <input
                            className= "inputField" 
                            autoComplete="off"
                            autoFocus
                            value = {this.state.searchString}
                            onChange={(event) => this.onSearchStringChange(event)}
                            //onBlur={(event) => this.onBranchNameBlur(event, branch)}
                        />
                    </div>
                </div>
                <div className="activityItemContainer">
                    <ActivityList
                        activities = {this.props.taxonomyQualifierData.activities.filter(d => d.name.toLowerCase().includes(this.state.searchString.toLowerCase()))}
                        objectives = {this.props.taxonomyQualifierData.objectives}
                    />
                </div>
            </div>
        );
    }
}

export default SearchPath;
  

