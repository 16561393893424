import React, {Component} from 'react'
import './Home.css';
class Home extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            taxonomyQualifierData: {}
        }
    }
    

    render() {
        //console.log("taxonomy tsrater")
        return (
            <div className="home">  
                <div className="covrImage">
                    <img  src="/img/front_LR.jpeg"/>
                </div>
                <div className="content">
                    <div className="tagline">
                        We make <br/><span className="hl-yellow">sustainability reporting</span><br/> easy 
                    </div>
                    <div className="intro">
                        We support companies with their sustainability accounting and reporting needs. We have developed a smart flexible sustainability accounting and reporting software - <span className="hl-yellow">SUSACC</span> - that makes collecting and reporting of sustainability data easy, no matter if for internal strategy measurements or for external communication.       
                    </div>
                </div>
            </div>
        )

    }
}

export default Home;
  

