import React, {Component} from 'react';
import './MainItemPath.css';
import ActivityList from '../../ActivityList/ActivityList';
import {BsFillCaretRightFill} from "react-icons/bs";

class  MainItemView extends Component {
    
    constructor(props) {
        super(props);
//        this.sectors = this.props.taxonomyQualifierData.sectors
        //this.objectives = this.props.taxonomyQualifierData.objectives.map(d => d.key = d.name) 
        // this.props.taxonomyQualifierData.activities.forEach(activity => {
        //     activity.screeningCriteria = this.props.taxonomyQualifierData.matches.find(d => activity.name === d.activity)
        // })
        // this.props.taxonomyQualifierData.sectors.forEach(sector => {
        //     sector.key = sector.name 
        // })
        this.mainItems = this.props.taxonomyQualifierData[(this.props.mainItemType + "s")] 
        this.state = {
            selectedMainItem: this.mainItems[0].key,
            selectedActivity: null
        }
    }

    onActivityClick(name) {
        this.setState({
            selectedActivity: name
        })
    }

    onMainItemClick(name) {
        this.setState({
            selectedMainItem: name
        })
    }

    render() {
        return (
            <div className="guideView">
                <div className="mainItemView">
                    <div className="mainItemHeading">{this.props.heading}</div>
                    <div className="mainItemItems">
                        {this.mainItems.map((mainItem) => (
                            <div 
                                key = {mainItem.key}
                                className={`mainItemItem ${this.state.selectedMainItem === mainItem.key ? "selectedItem" : ""}`}
                                onClick = {() => this.onMainItemClick(mainItem.key)}
                            >
                                <div className="mainItemIcon"><mainItem.Icon/></div>
                                <div className="name">{mainItem.name}</div>
                                {this.state.selectedMainItem === mainItem.name &&
                                    <div className="arrowIcon"><BsFillCaretRightFill/></div>
                                }
                            </div>
                        ))}
                    </div>
                </div>
                <div className="activityItemContainer">
                    <ActivityList
                        activities = {this.props.mainItemType === "objective" ? this.props.taxonomyQualifierData.activities.filter(d => d.screeningCriteria.some(d => d.objective === this.state.selectedMainItem)) : this.props.taxonomyQualifierData.activities.filter(d => d.sector === this.state.selectedMainItem)}
                        objectives = {this.props.taxonomyQualifierData.objectives}
                        objectiveSelected = {this.props.mainItemType === "objective" ? this.state.selectedMainItem : null}
                    />
                </div>
            </div>
        );
    }
}

export default MainItemView;
  

