//import logo from './logo.svg';
import './App.css';
import './styles.css'
import Menu from './Menu/Menu';
import Home from './Home/Home';
import Susacc from './Susacc/Susacc'
import TaxonomyQualifier from './TaxonomyQualifier/TaxonomyView/TaxonomyView' 
import Contact from './Contact/Contact';
import AboutUs from './AboutUs/AboutUs';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
  Link
} from "react-router-dom";

function App() {
  return (
    <Router>
      <div 
        className="app" 
        // onClick={()=>this.appClick()}
      > 
        <Menu/>
        <Routes>
          
          <Route path="/susacc" element={<Susacc/>}/>     
          <Route path="/taxonomyguide" element={<TaxonomyQualifier/>} /> 
          <Route path="/aboutus" element={<AboutUs/>} /> 
          <Route path="/contact" element={<Contact/>} /> 
          {/* <Route path="/" element={<Home/>}/>      */}
          <Route path="/*" element={<Home/>}/>     
        </Routes>
      </div>
      
    {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
  </Router>
      
);
}

export default App;
