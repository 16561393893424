import React, {Component} from 'react';
import MainItemPath from '../Paths/MainItemPath/MainItemPath'
import SearchPath from '../Paths/SearchPath/SearchPath'
import './Guide.css';
import {FaIndustry} from "react-icons/fa";
import {FiTarget, FiSearch} from "react-icons/fi";

class Guide extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            selectedGuide: null
        }
    }

    onGuideClick(guideId) {
        this.setState({selectedGuide: guideId})
    }



    render() {
        return (
            <div className="guide">  
                {this.state.selectedGuide === null &&
                    <div className="guideOptions">
                        <div 
                            className="option"
                            onClick={()=>this.onGuideClick("SECTOR")}
                        >
                            <div className="icon"><FaIndustry/></div>
                            <div>Find by sector</div>
                        </div> 
                        <div 
                            className="option"
                            onClick={()=>this.onGuideClick("OBJECTIVE")}
                        >
                            <div className="icon"><FiTarget/></div>
                            <div className="title">Find by objective</div>
                        </div> 
                        <div 
                            className="option"
                            onClick={()=>this.onGuideClick("SEARCH")}
                        >
                            <div className="icon"><FiSearch/></div>
                            <div className="title">Search activities</div>
                        </div> 
                    </div>
                }
                {this.state.selectedGuide !== null &&
                    <div 
                        className="backButton button"
                        onClick={()=>this.onGuideClick(null)}
                    >
                        Back
                    </div> 
                }
                {this.state.selectedGuide === "SECTOR" &&
                    <MainItemPath
                        taxonomyQualifierData = {this.props.taxonomyQualifierData}
                        mainItemType = {"sector"}
                        heading = {"Sectors"}
                    />
                }
                {this.state.selectedGuide === "OBJECTIVE" &&
                    <MainItemPath
                        taxonomyQualifierData = {this.props.taxonomyQualifierData}
                        mainItemType = {"objective"}
                        heading = {"Environmetal objectives"}
                    />
                }
                {this.state.selectedGuide === "SEARCH" &&
                    <SearchPath
                        taxonomyQualifierData = {this.props.taxonomyQualifierData}
                    />
                }
                
            </div>
        )
    }
};


export default Guide
  

