import React, {Component} from 'react'
import './Contact.css';

class Contact extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            taxonomyQualifierData: {}
        }
    }

    render() {
        //console.log("taxonomy tsrater")
        return (
            <div className="contact">  
                <div className="mainsection section centered contact">
                    <div className="heading main centered">
                        Contact us for more information
                    </div>
                    <div className="content">
                        <div className="paragraph">
                            To hear more about how Sustain Agents can help you and how the <span className="hl-yellow">SUSACC</span> software system works, please contact us on (European based): 
                        </div>
                        <div className="email">
                            Email:&nbsp; 
                            <a href="mailto:contact@sustainagents.com?subject=">contact@sustainagents.com</a> 
                        </div>
                        <div className="phone">
                            Phone:&nbsp;
                            <a href="tel:+45 61 17 70 00">+45 61 17 70 00</a> 
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

export default Contact;
  

