import React, {Component} from 'react'
import Guide from '../Guide/Guide';
import './TaxonomyView.css';

//objective icons
import { ImEarth } from "react-icons/im";   //climate mitigation
import { GiDam } from "react-icons/gi";   //climate adaption
import { BiWater } from "react-icons/bi";   //water
//import { MdRecycling } from "react-icons/md";   //circular
import { RiRecycleFill } from "react-icons/ri";   //circular
import { FaIndustry } from "react-icons/fa";   //polution
import { GiButterfly } from "react-icons/gi";   //diversity

//sector icons
import { FaTree } from "react-icons/fa";   //afforesty
import { GiIceShield } from "react-icons/gi"; //environmetal protection
import { FaIndustry as Manufactoring } from "react-icons/fa";   //manufactoring
import { GiElectric } from "react-icons/gi"; //energy  
import { IoIosWater } from "react-icons/io"; //water supply
import { FaTruckMoving } from "react-icons/fa"; //transport
import { FaBuilding } from "react-icons/fa"; //construction and real estate
import { MdComputer } from "react-icons/md"; //information and communication
import { BsFillPersonFill } from "react-icons/bs"; //professional and tecghinacal servcies
import { AiFillBank } from "react-icons/ai"; //finance and insurance
import { MdSchool } from "react-icons/md"; //education
import { IoMdHeart } from "react-icons/io"; //human health
import { FaTheaterMasks } from "react-icons/fa"; //Arts entertainment
import EU_taxonomy_compass from "../../resources/EU_taxonomy_compass.json"

class TaxonomyQualifier extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            taxonomyQualifierData: {}
        }
    }

    async componentDidMount() {
        let taxonomyQualifierData = EU_taxonomy_compass
        this.enrichData(taxonomyQualifierData)
        this.setState({taxonomyQualifierData})
    }

    enrichData(taxonomyQualifierData) {
        //combine activities and screening criteria (matches)
        taxonomyQualifierData.activities.forEach(activity => {
            activity.screeningCriteria = taxonomyQualifierData.matches.filter(d => activity.name === d.activity)
        })
        //make a key to aligned sector and objective keys  
        taxonomyQualifierData.sectors.forEach(sector => {
            sector.key = sector.name 
        })
        //add icons
        this.addIcons(taxonomyQualifierData)
        //make links to appendices 
        const replaceFrom = RegExp(/href="\.\.\/documents/g)
        const replaceTo = 'target="_blank" rel="noopener noreferrer" href="https://ec.europa.eu/sustainable-finance-taxonomy/documents'
        taxonomyQualifierData.activities.forEach(d => {
            d.description = d.description.replace(replaceFrom, replaceTo)
            d.screeningCriteria.forEach(d1 => {
                d1.substantial_contribution_criteria[0] = d1.substantial_contribution_criteria[0].replace(replaceFrom, replaceTo)
                d1.dnsh.forEach(d2 => {
                    d2.criteria[0] = d2.criteria[0].replace(replaceFrom, replaceTo)
                }) 
            })
        }) 
        let idx;
        // taxonomyQualifierData.activities.forEach(d => {
        //     idx = d.description.search(replaceFrom)
        //     if (idx == -1) {console.log(d.name, d.description)}
        //     d.screeningCriteria.forEach(d1 => {
        //         idx = d1.substantial_contribution_criteria[0].search(replaceFrom)
        //         if (idx == -1) {console.log(d.name, d1.substantial_contribution_criteria[0])}
        //         d1.dnsh.forEach(d2 => {
        //             idx = d2.criteria[0].search(replaceFrom)
        //             if (idx == -1) {console.log(d.name, d1.substantial_contribution_criteria[0])}
        //         }) 
        //     })
        // }) 
        // taxonomyQualifierData.activities.screeningCriteria.forEach(d => d.substantial_contribution_criteria[0].replace(/href="\.\.\/documents/g, 'target="_blank" rel="noopener noreferrer" href="https://ec.europa.eu/sustainable-finance-taxonomy/documents"')) 
        // taxonomyQualifierData.activities.screeningCriteria.forEach(d => d.dnsh.forEach(d1 => d1.criteria[0].replace(/href="\.\.\/documents/g, 'target="_blank" rel="noopener noreferrer" href="https://ec.europa.eu/sustainable-finance-taxonomy/documents"')))
    }

    addIcons(taxonomyQualifierData) {
        //objectioves
        taxonomyQualifierData.objectives[0].Icon = ImEarth
        taxonomyQualifierData.objectives[1].Icon = GiDam
        taxonomyQualifierData.objectives[2].Icon = BiWater
        taxonomyQualifierData.objectives[3].Icon = RiRecycleFill
        taxonomyQualifierData.objectives[4].Icon = FaIndustry
        taxonomyQualifierData.objectives[5].Icon = GiButterfly
        //sectors
        taxonomyQualifierData.sectors[0].Icon = FaTree
        taxonomyQualifierData.sectors[1].Icon = GiIceShield
        taxonomyQualifierData.sectors[2].Icon = Manufactoring
        taxonomyQualifierData.sectors[3].Icon = GiElectric
        taxonomyQualifierData.sectors[4].Icon = IoIosWater
        taxonomyQualifierData.sectors[5].Icon = FaTruckMoving
        taxonomyQualifierData.sectors[6].Icon = FaBuilding
        taxonomyQualifierData.sectors[7].Icon = MdComputer
        taxonomyQualifierData.sectors[8].Icon = BsFillPersonFill
        taxonomyQualifierData.sectors[9].Icon = AiFillBank
        taxonomyQualifierData.sectors[10].Icon = MdSchool
        taxonomyQualifierData.sectors[11].Icon = IoMdHeart
        taxonomyQualifierData.sectors[12].Icon = FaTheaterMasks
    }

    

    render() {
        //console.log("taxonomy tsrater")
        return (
            <div className="taxonomyQualifier">  
                <div className="taxonomyQualifierTitle">
                    <span>Sustain Agents'&nbsp;</span>  Taxonomy guide
                </div>
                <div className="taxonomyGuideContainer">
                    <Guide
                        taxonomyQualifierData = {this.state.taxonomyQualifierData}
                    />
                </div>
                <div className="taxonomyQualifierSource">
                    Source: EU's taxonomy compass
                </div>
            </div>
        )

    }
}

export default TaxonomyQualifier;
  

