import React, {Component} from 'react'
import { Link, NavLink } from "react-router-dom";
import './Menu.css';
import logo from '../Assets/logos/logo w name.svg'
import { BiMenu } from 'react-icons/bi' 

class Menu extends React.Component {
    
    constructor(props) {
        super(props);

        let isTouchDevice = () => {
            return (('ontouchstart' in window) ||
              (navigator.maxTouchPoints > 0) ||
              (navigator.msMaxTouchPoints > 0));
        }
        
        this.isTouchDevice = isTouchDevice()

        this.state = {
            mouseOverMenu: null,
            activeMainMenu: null,
            openSubMenu: null,
            showMenu: this.isTouchDevice ? false : true
        }
    }

    onMenuItemEnter(event, menuItem) {
        if (this.isTouchDevice) return
        this.setState({openSubMenu: menuItem})
    } 

    onMenuItemLeave() {
        if (this.isTouchDevice) return
        this.setState({openSubMenu: null})
    } 

    onMenuItemTouch() {
        if (this.isTouchDevice === false) return
        this.setState({showMenu: !this.state.showMenu})
    }

    render() {
        //console.log("taxonomy tsrater")

        return (
            <div className="topbar">

                
                <div className="logo">
                    <NavLink 
                        to="/"
                    >
                        <img src={logo} width="unset"/>
                    </NavLink>
                </div>
                {this.isTouchDevice &&
                    <div 
                        className="burger"
                        //onTouchStart={(event) => this.onMenuItemTouch(event)}
                        onClick={(event) => this.onMenuItemTouch(event)}
                    >
                        <BiMenu/>
                    </div>  
                }
                {this.state.showMenu &&
                <div className="menu"> 
                    <NavLink 
                        onClick={(event) => this.onMenuItemTouch(event)}
                        className={({ isActive}) => (isActive ? 'active' : 'inactive')} 
                        to="/susacc"
                        // isActive={(match, location) => {
                        //     console.log(match, location)
                        // }}
                    >
                        {/* <div className={`menuItem ${activeMainMenu === 'susacc' && 'active'} `}> */}
                        {/* <div className={`menuItem ${this.props.location.includes("susacc") ? 'active' : 'inactive'}`}> */}
                        <div className={`menuItem`}>
                            <div className="menuItemLabel">SUSACC</div>
                        </div>
                    </NavLink>
                    {/* <NavLink 
                        className={({ isActive}) => (isActive ? 'active' : 'inactive')} 
                        to="/taxonomyguide"
                    >
                        <div className="menuItem">
                            <div className="menuItemLabel">EU's Taxonomy</div>
                        </div>
                    </NavLink> */}
                    <div 
                        className="menuItem"
                        onMouseEnter={(event) => this.onMenuItemEnter(event, "taxonomy")}
                        onMouseLeave={() => this.onMenuItemEnter()}
                    >
                        <div className="menuItemLabel">
                            EU's Taxonomy
                        </div>
                        {(this.state.openSubMenu === 'taxonomy' || this.isTouchDevice) && 
                            <div className={`subMenuContainer`}>
                                {/* <NavLink 
                                    onClick={(event) => this.onMenuItemTouch(event)}
                                    className={({ isActive}) => (isActive ? 'active' : 'inactive')} 
                                    to="/taxonomyregulation"
                                >
                                    <div className="subMenuItem">
                                        <div className="subMenuItemLabel">What is EU's Taxonomy Regulation?</div>
                                    </div>
                                </NavLink> */}
                                <NavLink 
                                    onClick={(event) => this.onMenuItemTouch(event)}
                                    className={({ isActive}) => (isActive ? 'active' : 'inactive')} 
                                    to="/taxonomyguide"
                                >
                                    <div 
                                        className="subMenuItem"
                                    >
                                        <div className="subMenuItemLabel">Taxonomy guide</div>
                                    </div>
                                </NavLink>
                                {/* <NavLink 
                                    onClick={(event) => this.onMenuItemTouch(event)}
                                    className={({ isActive}) => (isActive ? 'active' : 'inactive')} 
                                    to="/taxonomyfaq">
                                    <div 
                                        className="subMenuItem"
                                    >
                                        <div className="subMenuItemLabel">FAQ</div>
                                    </div>
                                </NavLink> */}
                            </div>
                        }
                        
                    </div>
                    <NavLink 
                        onClick={(event) => this.onMenuItemTouch(event)}
                        className={({ isActive }) => (isActive ? 'active' : 'inactive')} 
                        to="/aboutus">
                        <div 
                            className="menuItem"
                        >
                            <div className="menuItemLabel">About us</div>
                        </div>
                    </NavLink>
                    <NavLink
                        onClick={(event) => this.onMenuItemTouch(event)}  
                        className={({ isActive }) => (isActive ? 'active' : 'inactive')} 
                        to="/contact">
                        <div
                            
                            className="menuItem"
                        >
                            <div className="menuItemLabel">Contact</div>
                        </div>
                    </NavLink>
                </div>
                }
            </div> 
        )
    }
}

export default Menu;
  

